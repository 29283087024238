import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getAllLanguageList } from '@api/i18n';
import store from 'store';
import { i18nList } from '@utils/customize';

/**
 * 多语言转换
 * @param data
 * @returns {{}}
 */
const transformData = data => {
    const languages = ['cs', 'de', 'en', 'es', 'hr', 'it', 'pl', 'pt', 'vi', 'zh'],
        result = {};

    for (const language of languages) {
        const translation = {};

        // eslint-disable-next-line guard-for-in
        for (const key in data) {
            // 结合i18next的回退操作 如果有数据就添加  没有数据则不添加,不管Code是否存在
            if (data[key][language]) {
                translation[data[key]?.code] = data[key][language];
            }
        }
        result[language] = { translation };
    }

    // console.log(result);
    return result;
};

/**
 * 获取多语言
 * @returns {Promise<void>}
 */
const languageList = async () => {
    const { successful, body } = await getAllLanguageList(),
        { content } = body;

    if (successful) {
        const result = transformData(content),
            langList = i18nList(),
            lang = navigator.language.replace(/-/, '_').toUpperCase(),
            findNavigatorTarget = langList.find(l => l.key.indexOf(lang) > -1);

        await i18next.use(initReactI18next).init({
            interpolation: {
                escapeValue: false // 禁用HTML转义
            },
            resources: result,
            lng: store.get('locale')?.slice(0, 2)?.toLowerCase() || langList[0]?.key?.slice(0, 2)?.toLowerCase(), // 默认语言
            // 如果浏览器语言在设置语言列表中没有找到 则退回语言按照英语  如果找到就按照找到的设置
            fallbackLng: () => {
                const locale = findNavigatorTarget?.key || langList[0].key;

                return locale.slice(0, 2)?.toLowerCase();
            }
        });
    }
};

export { languageList, i18next };
