import { createSlice } from '@reduxjs/toolkit';
import { queryUnredcount } from '@api/message';

export const loginSlice = createSlice({
    name: 'loginStore',
    initialState: {
        userInfo: {},
        agreeInfo: {},
        showNotice: false,
        unreadMessageData: {}
    },
    reducers: {
        setUserInfo: (state, action) => {
            return {
                ...state,
                userInfo: action.payload.userInfo,
                agreeInfo: action.payload.agreeInfo
            };
        },
        setShowNotice: (state, action) => {
            return {
                ...state,
                showNotice: action.payload
            };
        },
        setUnreadMessageCount: (state, action) => {
            return {
                ...state,
                unreadMessageData: action.payload
            };
        }
    }
});

// 导出 actions
export const { setUserInfo, setShowNotice, setUnreadMessageCount } = loginSlice.actions;

export default loginSlice.reducer;

/**
 * 获取未读消息
 * @param {*} messageType
 * @returns
 */
export const getUnreadMessageData = messageType => {
    return async dispatch => {
        const response = await queryUnredcount(messageType);

        // const data = {
        //     alarmCount: 0,
        //     systemCount: 0,
        //     allCount: 2,
        //     lastUserMessage: {
        //         _id: 'string',
        //         content: [
        //             {
        //                 content: '这是一条测试消息，这是一条测试消息',
        //                 language: 'string',
        //                 lan: 'zh',
        //                 title: 'string'
        //             }
        //         ],
        //         createDate: '2023-11-16T09:19:38.169Z',
        //         isOwnerUser: true,
        //         isRead: true,
        //         messageType: 'ALARM_NOTICE_MSG'
        //     }
        // };

        if (response?.successful) {
            dispatch(setUnreadMessageCount(response.body));
            // dispatch(setUnreadMessageCount(data));
        }
    };
};
