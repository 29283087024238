import { createSlice } from '@reduxjs/toolkit';
import { autoTestExport, autoTestUploadFile, settingUpload, checkStatus, preCheck, startCheck } from '@api/device';
import { scrollToBottom } from '@utils/tools/base';

let Interval = null;

export const deciceConfigSlice = createSlice({
    name: 'deviceConfigStore',
    initialState: {
        loading: false,
        autoStatus: false,
        preCheckData: {},
        checkingData: {},
        exportData: {},
        safetySettingCallbackStatus: false
    },
    reducers: {
        changeLoading: (state, action) => {
            return {
                ...state,
                loading: action.payload
            };
        },
        changeAutoStatus: (state, action) => {
            if (Interval) {
                clearInterval(Interval);
            }
            return {
                ...state,
                autoStatus: action.payload
            };
        },

        setPreCheck: (state, action) => {
            return {
                ...state,
                preCheckData: action.payload
            };
        },
        setCheckStatus: (state, action) => {
            return {
                ...state,
                checkingData: action.payload
            };
        },
        setExportData: (state, action) => {
            return {
                ...state,
                exportData: action.payload
            };
        },
        setSafetySettingCallbackStatus: (state, action) => {
            return {
                ...state,
                safetySettingCallbackStatus: action.payload
            };
        }
    }
});

// 导出 actions
export const { changeLoading, changeAutoStatus, setPreCheck, setCheckStatus, setExportData, setSafetySettingCallbackStatus } =
    deciceConfigSlice.actions;

export default deciceConfigSlice.reducer;

// 检测是否允许自检
export const getPreCheck = sn => {
    return async dispatch => {
        const response = await preCheck({ sn });

        if (response.successful) {
            dispatch(setPreCheck(response.body));
        }

        return response.body;
    };
};

// 获取自检状态
export const getCheckStatus = sn => {
    return async (dispatch, getState) => {
        Interval = setInterval(async () => {
            const response = await checkStatus({ sn });

            const autoData = response.body;

            const { autoStatus, preCheckData, checkingData } = getState().deviceConfigStore;

            if (response.successful) {
                // 首次添加
                if (checkingData.length === 0) {
                    await dispatch(setCheckStatus(autoData));
                    // 将滚动条位置设置为内容的高度，以保持在底部
                    const contentContainer = document.querySelector('#autoScorll');

                    if (contentContainer) {
                        scrollToBottom(contentContainer, 2500);
                    }
                }

                if (checkingData.currentTaskId !== autoData.currentTaskId) {
                    await dispatch(setCheckStatus(autoData));
                    // 将滚动条位置设置为内容的高度，以保持在底部
                    const contentContainer = document.querySelector('#autoScorll');

                    if (contentContainer) {
                        scrollToBottom(contentContainer, 2500);
                    }
                }

                if (autoData.result === '2' || autoData.result === '-1' || autoData.result === '0' || autoStatus === false) {
                    if (autoData.result === '0') {
                        if (!preCheckData?.isChecked && preCheckData?.deviceStatusAllow) {
                            dispatch(deviceStartCheck(sn));
                        }
                    } else {
                        await dispatch(setCheckStatus(autoData));

                        // 将滚动条位置设置为内容的高度，以保持在底部
                        const contentContainer = document.querySelector('#autoScorll');

                        if (contentContainer) {
                            scrollToBottom(contentContainer, 2500);
                        }
                    }

                    clearInterval(Interval);
                }
            }

            dispatch(changeLoading(false));
        }, 5000);
    };
};

// 开始自检
export const deviceStartCheck = sn => {
    return async dispatch => {
        const response = await startCheck({ sn });

        if (response?.successful) {
            dispatch(changeLoading(true));
            // 设备自检直接请求查询状态
            dispatch(getCheckStatus(sn));
        }
    };
};

// 查询导出相关信息
export const getAutoTestExport = sn => {
    return async dispatch => {
        const response = await autoTestExport({ sn });

        if (response?.successful) {
            dispatch(setExportData(response.body));
        }
    };
};

// autotest 上传PDF
export const autoTestUploadPdf = ({ file, sn, locale }) => {
    return async () => {
        const formData = new FormData();

        await formData.append('file', file, `${locale}/SN_${sn}.pdf`);

        await autoTestUploadFile(formData.get('file'));
    };
};

// 参数导出 上传PDF
export const exportSettingUploadPdf = ({ file, sn, locale }) => {
    return async () => {
        const formData = new FormData();

        await formData.append('file', file, `${locale}/SN_${sn}.pdf`);

        await settingUpload(formData.get('file'));
    };
};
