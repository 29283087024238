import { TQ_Get } from '@admin/utils/tools/axios';
import { PAGE_SIZE } from '@utils/constant';

/**
 * 分页获取消息记录
 */
export const queryMessageList = ({ messageType, pageNo = 1, pageSize = PAGE_SIZE }) =>
    TQ_Get('/system/message/list', {
        messageType,
        pageNo,
        pageSize
    });

// 阅读消息
export const readMessage = messageId => TQ_Get('/system/message/read', { messageId });

// 全部已读
export const readAll = messageType => TQ_Get('/system/message/readAll', { messageType });

/**
 * 未读消息数量
 * @param {*} messageType
 * @returns
 */

export const queryUnredcount = messageType => TQ_Get('/system/message/unread/count', { messageType });
