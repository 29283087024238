import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
// import thunkPromise from 'redux-thunk-promise';

import loginStore from '@admin/views/login/loginStore';
import deviceConfigStore from '@admin/views/device/components/config/deviceConfigStore';

const middleware = [thunk];

if (import.meta.env.MODE === 'dev') {
    middleware.push(createLogger());
}

export default configureStore({
    reducer: {
        loginStore,
        deviceConfigStore
    },
    middleware
});
