import { TQ_Get, TQ_Post } from '@admin/utils/tools/axios';
import { memoryCacheWrap } from '@utils/width-memory-cache';
import { getApiUserType } from '@utils';

/**
 * 删除设备
 */
export const removeDevice = deviceId =>
    TQ_Get(`/pc/${getApiUserType()}/station/device/delete`, {
        _: Date.now(),
        deviceId
    });

/**
 * 判别sn号是否存在
 */
export const isSnExist = ({ code, sn }) =>
    TQ_Get(`/pc/${getApiUserType()}/station/device/isSnExist`, {
        code,
        sn
    });

/**
 * 新增设备
 */
export const addDevice = data => TQ_Post(`/pc/${getApiUserType()}/station/device/add`, data);

/**
 * 扫描设备
 */
export const scanDevice = ({ addSns = [], stationId }) => {
    const paramStr = addSns.map(item => `addSns=${item}`).join('&');

    return TQ_Get(`/pc/owner/inverter/scanDevice?${paramStr}`, { stationId });
};

/**
 * 编辑设备
 */
export const modifyDevice = ({ deviceId, deviceName, meterType }) =>
    TQ_Post(
        `/pc/${getApiUserType()}/station/device/edit`,
        {},
        {
            deviceId,
            deviceName,
            meterType
        }
    );

/**
 * 换机
 */
export const swapDevice = ({ oldSn, sn, verifyCode, deviceName, stationId, comment, meterType }) =>
    TQ_Post(
        `/pc/${getApiUserType()}/station/device/switch`,
        {
            deviceName,
            sn,
            code: verifyCode,
            stationId,
            comment,
            meterType
        },
        { oldSn }
    );
/**
 * 固件升级列表
 * http://rap2.taobao.org/organization/repository/editor?id=312506&mod=537289&itf=2410643
 */
export const queryHistory = ({
    sn,
    startDate,
    endDate,
    operator,
    sortField,
    sortRule = 'DESC',
    result = 'ALL', // ALL, BURNING, BURN_FAILED, BURN_SUCCESS
    pageSize,
    pageNo
}) =>
    TQ_Post('/pc/firmware/device/firmware/taskList', {
        sn,
        startTime: startDate,
        endTime: endDate,
        result,
        operator,
        sortField,
        sortRule,
        pageSize,
        pageNo
    });

/**
 * 升级详情
 * http://rap2.taobao.org/organization/repository/editor?id=312506&mod=537289&itf=2410652
 */
export const queryDetail = memoryCacheWrap(
    ({ id, sn, status = 'ALL', operator, startTime, endTime, pageSize, beginPageNo, sortRule = 'DESC', sortField }) =>
        TQ_Post(`/pc/firmware/device/firmware/details/${id}`, {
            id,
            sn,
            operator,
            result: status,
            pageSize,
            pageNo: beginPageNo,
            sortRule,
            sortField,
            startTime,
            endTime
        })
);
/**
 * 获取固件烧录的详情
 *
 */
export const getFirmwareDesc = firmwareUrl => TQ_Post(`/pc/firmware/device/firmware/desc?firmwareUrl=${firmwareUrl}`);
/**
 * 上传批量升级设备列表
 * http://rap2.taobao.org/organization/repository/editor?id=312506&mod=537289&itf=2410655
 */
export const importDevices = snList => TQ_Post('/pc/firmware/brun/details', { snList });

/**
 * 执行计划
 * http://rap2.taobao.org/organization/repository/editor?id=312506&mod=537289&itf=2410700
 */
export const schedule = needBurnEntityList => TQ_Post('/pc/firmware/brun/save', needBurnEntityList);

/**
 * 模板下载
 */
export const queryTemplateUrl = memoryCacheWrap(() => TQ_Get('/iot/firmware/download/burn/template', { _: Date.now() }));

/**
 * 模板导入
 */
export const importTemplate = file =>
    TQ_Post(
        '/pc/firmware/import/burn/template',
        { file: file },
        {},
        {
            'Content-Type': 'multipart/form-data'
        }
    );

/**
 * 重试升级
 */
export const retryFirmwareUpgrade = taskId => TQ_Post(`/pc/firmware/burn/retry/${taskId}`);

/**
 * 删除任务
 */
export const removeFirmwareUpgradeTask = taskId => TQ_Post(`/pc/firmware/burn/delete/${taskId}`);

/**
 * 升级结果的枚举值
 */
export const queryFirmwareUpgradeResultEnums = memoryCacheWrap(() => TQ_Post('/pc/firmware/device/firmware/details/status'));

/**
 * 检测是否允许自检
 */
export const preCheck = ({ sn }) =>
    TQ_Get('/autoTest/preCheck', {
        deviceSn: sn
    });

/**
 * 获取自检状态
 */
export const checkStatus = ({ sn }) =>
    TQ_Get('/autoTest/status', {
        deviceSn: sn
    });

/**
 * 开始自检
 */
export const startCheck = ({ sn }) =>
    TQ_Get('/autoTest/start', {
        deviceSn: sn
    });

/**
 * autoTest 导出相关信息查询
 */
export const autoTestExport = ({ sn }) =>
    TQ_Get('/autoTest/export', {
        deviceSn: sn
    });
/**
 * CT检测
 * 开始检测
 */
export const startCtCheck = ({ sn, meterStatus }) =>
    TQ_Post('/meterCheck/startCheck', {
        sn,
        meterStatus
    });
/**
 * CT检测
 * 轮询查询检测结果
 */
export const getCheckResult = ({ sn }) =>
    TQ_Get('/meterCheck/getCheckResult', {
        sn
    });
/**
 * CT检测
 * 获取设备状态，电池连接状态，电表连接状态
 */
export const getInerterBMSMeterStatus = ({ sn }) =>
    TQ_Get('/meterCheck/getInerterBMSMeterStatus', {
        sn
    });

// autoTest 上传接口
export const autoTestUploadFile = file =>
    TQ_Post(
        `/oss/_uploadFile/autoTest`,
        { file: file },
        {},
        {
            'Content-Type': 'multipart/form-data'
        }
    );

// 参数导出 上传接口
export const settingUpload = file =>
    TQ_Post(
        `/oss/_uploadFile/setting`,
        { file: file },
        {},
        {
            'Content-Type': 'multipart/form-data'
        }
    );

// 单独下发安规
export const setSaftyCountry = ({ deviceSn, cmd }) =>
    TQ_Post('/sCmd/setSaftyCountry', {
        deviceSn,
        cmd
    });

// 设备基本信息
export const basicExportInfo = params => TQ_Get('/device/basic/exportInfo', params);

// 参数设置所有下拉列表获取
export const getDeviceSelectList = params => TQ_Get('/dict/getSelectList', params);

// 设备是否在固件升级中
export const burnStatus = params => TQ_Get('/pc/firmware/burnStatus', params);

// 充电桩设备信息
export const charge_basic_info = params => TQ_Get(`/station/device/charge/pc/${params?.sn}/basic_info`, params);

// 获取时间组
export const time_array = params => TQ_Get(`/station/device/charge/${params.sn}/timeArray`, params);

// 预约充电时间组
export const reserTimeArray = params => TQ_Post('/station/device/charge/reservation/timeArray', params);

// 停止充电
export const stopCharging = params => TQ_Get(`/station/device/charge/stop/charging/${params?.sn}`, params);

// 立即充电
export const startCharging = params => TQ_Post('/station/device/charge/start/charging', params);

// 预约充电
export const reservationCharging = params => TQ_Post(`/station/device/charge/reservation/charging/${params.sn}`, params);

// 充电桩告警数据量
export const queryalarmNums = params => TQ_Get('/pc/distributor/alarm/queryalarmNums', params);

// 专业参数导出
export const inverterExport = params => TQ_Post(`/pc/owner/inverter/${params.sn}/running/history/export`, params);
