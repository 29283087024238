import store from 'store';
import { getCountryLanguageList } from '@admin/api/i18n';
// 返回odm 授权的多语言种类
export const i18nList = async (odm = store.get('odm')) => {
    const response = await getCountryLanguageList({
        client: odm
    });

    if (response.successful) {
        const { body } = response;
        const result = body.map(item => {
            return {
                key: item?.code.toLocaleUpperCase(),
                text: item?.language
            };
        });

        store.set('odm-list', result);
    }
};
