import { logout as logoutApi } from '@api/login';
import store from 'store';
import dayjs from 'dayjs';
import { translation } from '@admin/utils/tools/base';
import { getUseUserBaseInfo } from '@utils/useBaseUserInfo';

/**
 * 隐藏不必要的警告
 */
const injactConsoleError = window.console.error;
const ignoreMessage = [
    "Warning: [antd: message] Static function can not consume context like dynamic theme. Please use 'App' component instead.",
    "Warning: [antd: Modal] Static function can not consume context like dynamic theme. Please use 'App' component instead.",
    'A disconnected element was passed.',
    "Uncaught TypeError: Cannot read properties of null (reading 'right')"
];

window.onerror = function (message) {
    if (ignoreMessage.includes(message)) {
        // 如果错误信息包含你想忽略的信息，返回true来阻止错误进一步传播
        return true;
    }
    // 否则，让错误继续传播，让其他错误处理器可以处理它
    return false;
};

window.console.error = function () {
    if (!ignoreMessage.includes(arguments[0])) {
        injactConsoleError.apply(this, arguments);
    }
};

export const logout = async () => {
    const { successful } = await logoutApi();

    return new Promise(resolve => {
        store.remove('token');
        store.remove('permission');
        store.remove('emptyStationFlag');
        store.remove('emptyDeviceFlag');
        // store.remove('odm-list');
        resolve({ successful });
    });
};

export const getUserType = () => {
    const {
        baseUserInfo: { userType }
    } = getUseUserBaseInfo();
    // const { userType } = getBaseUserInfo() || {};

    return (userType ?? '').toLowerCase() || 'distributor';
};

/**
 * api 的角色
 * plant 在后台其实是 root 权限的 distributor
 * 所以 api 接口仍然使用 distributor
 */
export const getApiUserType = () => {
    const userType = getUserType();

    if (userType === 'plant') {
        return 'distributor';
    }
    return userType;
};

export const getWeekDate = date => {
    switch (new Date(date).getDay()) {
        case 0:
            return translation('week.sunday');
        case 1:
            return translation('week.monday');
        case 2:
            return translation('week.tuesday');
        case 3:
            return translation('week.wednesday');
        case 4:
            return translation('week.thursday');
        case 5:
            return translation('week.friday');
        case 6:
            return translation('week.saturday');
        default:
            return '--';
    }
};

// 返回告警状态名
export const getAlarmStatusName = alarmStatus => {
    switch (alarmStatus) {
        case 'CURRENT':
            return 'warn.currentAlarm';
        case 'HISTORY':
            return 'warn.historyAlarm';
        case 'ALL':
            return 'base.all';
        default:
            return '--';
    }
};

// 返回告警类型名
export const getAlarmTypeName = alarmType => {
    switch (alarmType) {
        case 'PROTECTED':
            return translation('warn.protect');
        case 'FAULT':
            return translation('device.fault');
        default:
            return '--';
    }
};

// 生成组织树
export const genOrgTree = (list, isMenu = false, depth = 1, parentKeys = []) =>
    list.map(({ email, name, id, orgCode, children }) => {
        const isLeaf = !children || !children.length;
        const node = {
            value: id,
            key: id,
            title: name,
            orgCode,
            isLeaf,
            depth,
            parentKeys,
            email,
            selectable: isLeaf
        };

        if (typeof isMenu === 'boolean') {
            node.selectable = isMenu || isLeaf;
        } else if (typeof isMenu === 'function') {
            // 使用函数动态判断
            node.selectable = isMenu(node);
        }

        if (!isLeaf) {
            node.children = genOrgTree(children, isMenu, depth + 1, [...parentKeys, id]);
        }
        return node;
    });

// 过滤路由
export const filterRouterByUserInfo = (routes, userBaseInfo) => {
    const { roleType, userType } = userBaseInfo;

    return routes.filter(item => {
        const { showIn, showInRoles } = item;

        /**
         * 账户类型过滤
         * 路由上没有 showIn 表示所有账户类型都可以访问
         */
        if (!showIn || showIn.includes(userType)) {
            /**
             * 表示在当前账户类型下显示路由
             * 进入角色类型判断
             * 路由上没有 showInRoles，表示所有角色都可以访问
             */
            if (!showInRoles) {
                return true;
            }
            return showInRoles.includes(roleType);
        }
        return false;
    });
};

// 固件升级结果
export const getFirmwareUpdateResultName = result => {
    switch (result) {
        case 'BURN_FAILED':
            return translation('directConnection.basicParams.failed');
        case 'BURN_SUCCESS':
            return translation('directConnection.basicParams.success');
        case 'BURNING':
        case 'BURN_INIT':
        case 'ALL':
        default:
            return '--';
    }
};

// 数字显示
export const displayNumber = num => {
    if (num !== '-' && num !== '--') {
        return Number(num).toFixed(2);
    }
    return num;
};

// 按日期类型返回日期格式
export const getDateFormatByDateType = (defaultDateFormat, dateType) => {
    switch (dateType) {
        case 'month':
            return defaultDateFormat.replace(/[^YMD]?.DD|DD.[^YMD]?/, '');
        case 'year':
            return defaultDateFormat.replace(/[^YMD]?DD[^YMD]?/, '.').replace(/[^YMD]?MM[^YMD]?/, '');
        default:
            return defaultDateFormat;
    }
};

// 返回图表的 xAxisPointerLabel
export const getXAxisPointerLabel = ({ value, defaultDateFormat, dateType, date }) => {
    switch (dateType) {
        case 'sum':
            return value;
        case 'year': {
            const dateFormat = getDateFormatByDateType(defaultDateFormat, 'month');

            return dayjs(`${date}-${value}`).format(dateFormat);
        }
        case 'month': {
            const dateFormat = getDateFormatByDateType(defaultDateFormat, 'date');

            return dayjs(`${date}-${value}`).format(dateFormat);
        }
        case 'date': {
            const dateFormat = getDateFormatByDateType(defaultDateFormat, 'date');

            return `${value} ${dayjs(`${date}`).format(dateFormat)}`;
        }
        default:
            return '-';
    }
};

export const getStationStatusName = status => {
    switch (status) {
        case 'ONLINE':
            return 'state.normal';
        case 'FAULT':
            return 'station.fail';
        case 'OFFLINE':
            return 'state.offline';
        case 'ALL':
            return 'base.all';
        default:
            return '--';
    }
};

export const getDeviceStatusName = status => {
    switch (status) {
        case 'ONLINE':
        case 'NORMAL':
            return translation('state.normal');
        case 'FAULT':
            return translation('station.fail');
        case 'OFFLINE':
            return translation('state.offline');
        case 'STANDBY':
            return translation('state.standby');
        case 'ALL':
            return translation('base.all');
        default:
            return '--';
    }
};

export const setUnitConversion = value => {
    if (value === null || value === undefined) {
        return '';
    }

    const unitEnum = ['', 'k ', 'M ', 'G ', 'T '];
    const unit = unitEnum[value.split(',')?.length - 1];

    return unit;
};

export const setValueConversion = value => {
    if (value === null || value === undefined) {
        return '--';
    }
    let val = '';

    val = value?.split(',').length === 1 ? Number(value).toFixed(2) : Number((value.split(',')[0] + value.split(',')[1]) / 1000).toFixed(2);

    return val;
};

// 用户角色分类
export const userTypeList = () => {
    return [
        { type: 'SUPER_ADMIN', roleCode: 'role.super_admin' },
        { type: 'ADMIN', roleCode: 'role.admin' },
        { type: 'ORDINARY', roleCode: 'role.ordinary' },
        { type: 'VISITOR', roleCode: 'role.visitor' },
        { type: 'DEMO', roleCode: 'role.demo' }
    ];
};
