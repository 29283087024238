import { TQ_Post, TQ_Get } from '@admin/utils/tools/axios';
// import { memoryCacheWrap } from '@utils/width-memory-cache';

export const messageList = (param = {}) => TQ_Get('/index-infos', param);

export const login = ({ siteName, account, pwd }) =>
    TQ_Post('/pc/user/login', {
        siteName,
        account,
        pwd
    });

// 退出登录
export const logout = () => TQ_Get('/pc/user/logout', { _: Date.now() });

// 检测用户是否登录过
export const userCheck = params => TQ_Post('/pc/user/check', params);

// 系统设置、修改密码步骤引导
export const userCheckSteps = () => TQ_Get('/pc/user/check/steps');

// 更新政策同意时间
export const policyAgreeTime = params => TQ_Post('/pc/user/modify/policyAgreeTime', params);

// 获取当前属于哪个站点
export const metaHost = params => TQ_Get('/meta_host', params);

// 维护界面
export const heartBeat = params => TQ_Get('/heartBeatV2', params);
